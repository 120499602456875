import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Altarretabel1 from "../components/altarretabel1"
import Altarretabel2 from "../components/altarretabel2"
import Altarretabel3 from "../components/altarretabel3"

import "./index.css"

export default function AltarretabelPage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Altarretabel"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Altarretabel"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Altarretabel</h1>
        <h2 className="referenceH2">
          Barocker Hochaltar und Seitenaltäre in der Stadtpfarrkirche St.
          Magdalena, Herzogenaurach
        </h2>
        <h3 className="referenceH3">
          Konservierung der Vergoldung mit Hausenblasenleim, Reinigung der
          Metallauflagen, Fehlstellenergänzung in Form von Kreidegrundkittung
          und Retusche
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Altarretabel1">
              <Altarretabel1 id="Altarretabel1" />
              Gesamter Altarretabel
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Altarretabel2">
              <Altarretabel2 id="Altarretabel2" />
              Zwischenzustand nach der Konservierung hochstehender
              Malschichtschollen mit Hausblasenleim und der Fehlstellenergänzung
              mit Kreidegrund
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Altarretabel3">
              <Altarretabel3 id="Altarretabel3" />
              Endzustand nach der Retusche
              <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Es handelt sich um ein viergeschossiges furniertes Altarretabel,
              dessen Zierrat und Säulen mit Metallauflagen in einer
              Polimentvergoldung gearbeitet sind. Die Blattvergoldungen sind das
              charakteristische Element des Altars. Die Mittelnische wird von
              einer Kreuzigungsgruppe dominiert, deren Gewänder ebenso vergoldet
              sind. Dies betrifft auch die anderen Figuren des Altars. Den
              Hochaltar schuf Bernhard Häusler um 1699/1700. Der linke
              Seitenaltar (hier nicht zu sehen) mit Madonnenskulptur von 1420
              entstand 1760. Der rechte Seitenalter (nicht abgebildet) wurde
              nach einer Kopie von Bernhard Kamm (1774) gefertigt. Er zeigt in
              der Mittelnische die Darstellung des Hl. Sebastian von 1780.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Die Blattvergoldungen waren besonders in den unteren Zonen stark
              berieben. Hochstehende Metallauflagen waren in allen Bereichen zu
              verzeichnen. Insbesondere die Farbfassung und Vergoldung der
              Kruzifixdarstellung war stark geschädigt. Fassungsverluste waren
              bereits eingetreten.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Im Vordergrund stand die Substanzsicherung der Farbfassung und
              Vergoldung mit Hausenblasenleim. Darüber hinaus waren die
              Metallauflagen zu reinigen. Fehlstellen und Bereiche, die stark
              berieben waren, sollten mit Pudergold retuschiert werden. Die
              Fehlstellen im Inkarnat des Christuskorpus sind zu schließen.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Die hochstehende Farbfassung des Christuskorpus wurde konserviert
              und Fehlstellen mit Kreidegrund aufgefüllt. Die retuschierten
              Fehlstellen integrierten sich nach der Retusche wieder in die
              gealterte Farbfassung.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">Restaurator Erwin Rösch.</p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Erzbischöfliches Bauamt Bamberg, Dipl.-Ing. Herbert Van Beek.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
